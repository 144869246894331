import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import React, { useState, useEffect } from "react"
import Select from "react-select"
import {
  retrieveSportImages,
  retrieveSports,
  updateField,
} from "helpers/fakebackend_helper"
import { CheckBox } from "pages/Dashboard/LatestTranactionCol"
import {
  BsCheck,
  BsCheck2Circle,
  BsCheckCircleFill,
  BsCurrencyEuro,
} from "react-icons/bs"

const Fieldsedit = ({ show, onHide, data, handleUpdate }) => {
  const [pending, setPending] = useState(false)
  const [sports, setSports] = useState([])
  const [formData, setFormData] = useState({})
  const [sportImages, setSportImages] = useState([])
  const [pages, setPages] = useState({
    amount: 1,
    current: 1,
  })
  useEffect(() => {
    setFormData(v => ({ ...v}))
  }, [show])
  useEffect(() => {
    if (data && data.sports) {
      const formattedSports = data.sports.map(v => ({
        label: v.value,
        value: v.id,
      }))
      //append one to openings
      const openings = data.openings
      openings.push({
        time_from: "",
        time_to: "",
        opening_time_booking: "",
      })
      setFormData({ ...data, sports: formattedSports, openings: openings })
    }
  }, [data])
  const loadFields = async () => {
    const data = await retrieveSports()
    setSports(data)
  }
  const loadImages = async () => {
    const data = await retrieveSportImages()
    setSportImages(data)
  }
  useEffect(() => {
    loadFields()
    loadImages()
  }, [])

  useEffect(() => {
    setPages(v => ({ ...v, amount: Math.ceil(sportImages.length / 3) }))
  }, [sportImages.length])

  const handleSubmit = async () => {
    setPending(true)
    let request = formData
    request.sports = request.sports.map(v => v.value)
    //filter all the request.openings that are not "" in time_from or time_to or opening_time_booking
    request.openings = request.openings.filter(
      v => v.time_from != "" && v.time_to != "" && v.opening_time_booking != ""
    )

    const conf = confirm("Vuoi rigenerare anche i timeslot?")
    const response = await updateField({
      ...request,
      regenerate_timeslots: conf,
    })
    if (response.status) {
      await handleUpdate()
      setFormData({})
      onHide()
      alert("Campo modificato")
      setPending(false)
    } else {
      alert("Errore nella modifica del campo")
      setPending(false)
    }
  }

  const pageButtons = []
  for (let c = 1; c <= pages.amount; c++) {
    pageButtons.push(
      <Button
        onClick={() => setPages(el => ({ ...el, current: c }))}
        color={pages.current == c ? "primary" : "outline-primary"}
      >
        {c}
      </Button>
    )
  }

  const handleSplice = i => {
    setFormData(v => ({
      ...v,
      openings: v.openings.filter((l, j) => j != i),
    }))
  }
  const handleAppend = () => {
    setFormData(v => ({
      ...v,
      openings: [
        ...v.openings,
        { time_from: "07:00", time_to: "23:00", opening_time_booking: "07:00" },
      ],
    }))
  }

  return (
    <Modal isOpen={show} toggle={onHide} size="lg">
      <ModalHeader>Gestione campo</ModalHeader>
      {formData && (
        <ModalBody>
          <Container>
            <h1 className="mt-1">Informazioni generali</h1>
            <Row>
              <Col>
                <label>Nome campo</label>
                <Input
                  value={formData.name}
                  onChange={e =>
                    setFormData(v => ({ ...v, name: e.target.value }))
                  }
                />
              </Col>

              <Col>
                <label>Pubblico</label>
                <div>
                  <Input
                    type="checkbox"
                    className="mx-1"
                    onClick={e =>
                      e.target.checked
                        ? setFormData(v => ({ ...v, public: 0 }))
                        : setFormData(v => ({ ...v, public: 1 }))
                    }
                    checked={formData.public == 1}
                  />
                </div>
              </Col>
            </Row>

            <label>Foto</label>
            <div className="p-2 rounded bg-light justify-content-around d-flex position-relative">
              {sportImages
                .filter(
                  (v, i) =>
                    i >= (pages.current - 1) * 3 && i < pages.current * 3
                )
                .map((v, i) => (
                  <div
                    key={i}
                    className="position-relative justify-content-center align-items-center d-flex mx-1 overflow-hidden rounded"
                  >
                    <img
                      src={v.path}
                      height={"150px"}
                      onClick={() =>
                        setFormData(f => ({ ...f, photo: v.path }))
                      }
                    />
                    {formData.photo == v.path ? (
                      <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center backdropBlur">
                        <BsCheckCircleFill size={28} className="text-white " />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
            </div>
            <InputGroup className="my-2">{pageButtons}</InputGroup>
            <Row>
              <Col>
                <label>Sport praticabile</label>
                <Select
                  isMulti
                  value={formData.sports}
                  onChange={e => setFormData(v => ({ ...v, sports: e }))}
                  options={sports.map(v => ({ value: v.id, label: v.name }))}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <label>Note</label>
                <textarea
                  value={formData.notes}
                  className="form-control"
                  onChange={e => {
                    setFormData(v => ({ ...v, notes: e.target.value }))
                  }}
                ></textarea>
              </Col>
            </Row>
            <h1 className="mt-1">Slot</h1>
            <Row>
              <Col>
                <label>Prenotazione per:</label>
                <div className="d-flex flex-column">
                  <div className="d-flex">
                    <Input
                      type="checkbox"
                      className="mx-1"
                      onClick={e =>
                        e.target.checked
                          ? setFormData(v => ({ ...v, booking_order: 2 }))
                          : setFormData(v => ({ ...v, booking_order: 1 }))
                      }
                      checked={formData.booking_order == 1}
                    />
                    <label>Ombrellone</label>
                  </div>
                  <div className="d-flex">
                    <Input
                      type="checkbox"
                      className="mx-1"
                      onClick={e =>
                        e.target.checked
                          ? setFormData(v => ({ ...v, booking_order: 1 }))
                          : setFormData(v => ({ ...v, booking_order: 2 }))
                      }
                      checked={formData.booking_order == 2}
                    />
                    <label>Utente</label>
                  </div>
                </div>
              </Col>
              <Col>
                <label>Massimo prenotazioni (0 se illimitato)</label>
                <InputGroup>
                  <Input
                    type="number"
                    min={0}
                    onChange={e =>
                      setFormData(v => ({
                        ...v,
                        max_bookings_per_umbrella: e.target.value,
                      }))
                    }
                    value={formData.max_bookings_per_umbrella}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <label>Durata slot</label>
                <InputGroup>
                  <Input
                    type="number"
                    value={formData.duration}
                    onChange={e =>
                      setFormData(v => ({ ...v, duration: e.target.value }))
                    }
                  />
                  <InputGroupText>Min.</InputGroupText>
                </InputGroup>
              </Col>
              <Col>
                <label> </label>
                <div className="d-flex mt-3">
                  <Input
                    type="checkbox"
                    className="mx-1"
                    onClick={() =>
                      formData.consecutive == 1
                        ? setFormData(el => ({ ...el, consecutive: 0 }))
                        : setFormData(el => ({ ...el, consecutive: 1 }))
                    }
                    checked={formData.consecutive == 1}
                  />
                  <label> Permetti di prenotare più slot consecutivi</label>
                </div>
              </Col>
            </Row>
            {/* <Row className="mt-2">
              <Col>
                <label>Apertura prenotazioni giornaliero</label>
                <Input
                  type="time"
                  value={formData.opening_time_booking}
                  onChange={e =>
                    setFormData(v => ({
                      ...v,
                      opening_time_booking: e.target.value,
                    }))
                  }
                />
              </Col>
              <Col>
                <label>Considera notturno dopo le:</label>
                <Input
                  type="time"
                  value={formData.nocturnal_time}
                  onChange={e =>
                    setFormData(v => ({ ...v, nocturnal_time: e.target.value }))
                  }
                />
              </Col>
            </Row> */}
            {formData.openings &&
              formData.openings.map((v, i) => (
                <Row className="mt-2" key={i}>
                  <Col>
                    <label>Dalle:</label>
                    <Input
                      type="time"
                      value={v.time_from}
                      onChange={e =>
                        setFormData(v => ({
                          ...v,
                          openings: v.openings.map((l, j) => {
                            if (j == i) {
                              return { ...l, time_from: e.target.value }
                            }
                            return l
                          }),
                        }))
                      }
                    />
                  </Col>
                  <Col>
                    <label>Alle:</label>
                    <Input
                      type="time"
                      value={v.time_to}
                      onChange={e =>
                        setFormData(v => ({
                          ...v,
                          openings: v.openings.map((l, j) => {
                            if (j == i) {
                              return { ...l, time_to: e.target.value }
                            }
                            return l
                          }),
                        }))
                      }
                    />
                  </Col>
                  <Col>
                    <label>Apertura prenotazioni</label>
                    <Input
                      type="time"
                      value={v.opening_time_booking}
                      onChange={e =>
                        setFormData(v => ({
                          ...v,
                          openings: v.openings.map((l, j) => {
                            if (j == i) {
                              return {
                                ...l,
                                opening_time_booking: e.target.value,
                              }
                            }
                            return l
                          }),
                        }))
                      }
                    />
                  </Col>
                  <Col className="d-flex flex-column" xs={2}>
                    <label>&nbsp;</label>
                    <div className="d-flex ">
                      <Button color="danger" onClick={() => handleSplice(i)}>
                        -
                      </Button>
                      {i == formData.openings.length - 1 && (
                        <Button
                          color="primary"
                          className="mx-1"
                          onClick={() => handleAppend()}
                        >
                          +
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              ))}
            <Row className="mt-2">
              <Col>
                <label>Considera notturno dopo le:</label>
                <Input
                  type="time"
                  value={formData.nocturnal_time}
                  onChange={e =>
                    setFormData(v => ({ ...v, nocturnal_time: e.target.value }))
                  }
                />
              </Col>
              <Col>
                <label>Costo diurno a slot</label>
                <InputGroup>
                  <Input
                    type="number"
                    value={formData.diurnal_price}
                    onChange={e =>
                      setFormData(v => ({
                        ...v,
                        diurnal_price: e.target.value,
                      }))
                    }
                  />
                  <InputGroupText>
                    <BsCurrencyEuro />
                  </InputGroupText>
                </InputGroup>
              </Col>
              <Col>
                <label>Costo notturno a slot</label>
                <InputGroup>
                  <Input
                    type="number"
                    value={formData.nocturnal_price}
                    onChange={e =>
                      setFormData(v => ({
                        ...v,
                        nocturnal_price: e.target.value,
                      }))
                    }
                  />
                  <InputGroupText>
                    <BsCurrencyEuro />
                  </InputGroupText>
                </InputGroup>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      )}
      <ModalFooter>
        <Button color="secondary" onClick={() => onHide()}>
          Annulla
        </Button>
        <Button
          color="primary"
          onClick={() => handleSubmit()}
          disabled={pending}
        >
          Salva
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Fieldsedit
