import {
  Button,
  Card,
  CardBody,
  Container,
  Input,
  InputGroup,
  Table,
} from "reactstrap"
import React from "react"
import { useState } from "react"
import {
  changeVisibilityField,
  deleteField,
  getAnagrafica,
  loadFields,
  patchBookingMasterField,
} from "helpers/fakebackend_helper"
import { useEffect } from "react"
import Slotmodal from "components/fields/Slotmodal"
import Fieldsedit from "components/fields/Fieldsedit"
import Createfield from "components/fields/Createfield"
import { checkIfUserHasPurchasedModule } from "helpers/functions"
import Bookings from "components/fields/Bookings"
import Timeslots from "components/fields/Bookingtimeslots"

export default function Index() {
  useEffect(() => {
    checkIfUserHasPurchasedModule(5)
  }, [])
  const [fields, setFields] = useState([])
  const [update, setUpdate] = useState(false)
  const [slotModals, setSlotModals] = useState({
    show: false,
    data: {},
  })
  const [bookings, setBookings] = useState({
    show: false,
    data: {},
  })
  const [Bookingtimeslots, setBookingtimeslots] = useState({
    show: false,
    data: false,
  })

  const [maxBookings, setMaxBooking] = useState(0)
  const [newFieldModal, setNewFieldModal] = useState(false)
  const [fieldModals, setFieldModals] = useState({
    show: false,
  })
  const loadData = async () => {
    const data = await getAnagrafica()
    setMaxBooking(data.max_bookings_per_umbrella)
  }
  const handleDelete = async id => {
    const conf = confirm("Sei sicuro di eliminare questo campo?")
    if (conf) {
      const data = await deleteField(id)
      if (data.status) {
        getFields()
        setUpdate(v => !v)
        alert("Campo eliminato")
      }
    }
  }
  useEffect(() => {
    loadData()
  }, [])
  const updateBookingMaster = async () => {
    const data = await patchBookingMasterField(maxBookings)
    if (data == "ok") {
      alert("Prenotazioni massime aggiornate")
    }
  }
  const [toUpdate, setToUpdate] = useState(false)
  const getFields = async () => {
    const data = await loadFields()
    const orderedTimeslots = data.map(v => {
      const orderedTimeslot = []
      v.timeslots.forEach(el => {
        const inTimeslot = orderedTimeslot.findIndex(el2 => el2.key == el.day)

        if (inTimeslot == -1) {
          const obj = {
            key: el.day,
            label: el.day,
            nocturnal_time: el.nocturnal_time,
            opening_time_booking: el.opening_time_booking,
            max_bookings: el.max_bookings_per_umbrella,
            duration: el.duration,
            description: el.description,
            consecutive: el.consecutive,
            booking_order: el.booking_order,
            nocturnal_price: el.nocturnal_price,
            diurnal_price: el.diurnal_price,
            photo: el.photo,
            id: el.id,
            slots: [
              {
                id: el.id,
                start_slot_time: el.start_slot_time,
                end_slot_time: el.end_slot_time,
                price: el.price,
                type: el.type,
              },
            ],
          }
          orderedTimeslot.push(obj)
        } else {
          orderedTimeslot[inTimeslot].slots.push({
            id: el.id,
            start_slot_time: el.start_slot_time,
            end_slot_time: el.end_slot_time,
            price: el.price,
            type: el.type,
          })
        }
      })
      return { ...v, slots: orderedTimeslot }
    })
    if (slotModals.show) {
      //find v through the id of slotModals in orderedTimeslots
      const index = orderedTimeslots.findIndex(v => slotModals.data.id == v.id)
      if (index !== -1) {
        console.log(orderedTimeslots)
        setSlotModals(v => ({
          ...v,
          data: {
            ...v.data,
            slots: orderedTimeslots[index].slots,
          },
        }))
      }
    }

    setFields(orderedTimeslots)
  }

  useEffect(() => {
    getFields()
  }, [])
  useEffect(() => {
    if (slotModals.data.id) {
      const index = fields.findIndex(v => slotModals.data.id == v.id)
      if (index !== -1) {
        setSlotModals(v => ({
          ...v,
          data: {
            ...v.data,
            slots: fields[index].slots,
          },
        }))
      }
    }
  }, [toUpdate])

  useEffect(() => {
    console.log("aggiornato")
    console.log(slotModals)
    if (slotModals.data.id) {
      console.log("yes")
      const index = fields.findIndex(v => slotModals.data.id == v.id)
      if (index !== -1) {
        setSlotModals(v => ({
          ...v,
          data: {
            ...v.data,
            slots: fields[index].slots,
          },
        }))
      }
    }
  }, [fields])

  useEffect(() => {
    console.log(slotModals)
  }, [slotModals.data])

  const handleDisable = async id => {
    const data = await changeVisibilityField(id)
    if (data.status) {
      getFields()
    }
  }

  const handleBookingOpening = id => {
    setBookings(v => ({ ...v, show: true, data: id }))
  }
  return (
    <>
      <Bookings
        show={bookings.show}
        onHide={() => setBookings(v => ({ ...v, show: false }))}
        id={bookings.data}
      />
      <Createfield
        data={fields.length + 1}
        handleUpdate={getFields}
        show={newFieldModal}
        onHide={() => setNewFieldModal(false)}
      />
      <Fieldsedit
        handleUpdate={getFields}
        show={fieldModals.show}
        data={fieldModals.data}
        onHide={() => setFieldModals(v => ({ ...v, show: false }))}
      />
      <Slotmodal
        handleUpdate={getFields}
        toUpdate={setToUpdate}
        updateData={setSlotModals}
        show={slotModals.show}
        data={slotModals.data}
        onHide={() => setSlotModals(v => ({ ...v, show: false }))}
      />
      <Timeslots
        onHide={() => setBookingtimeslots(v => ({ ...v, show: false }))}
        show={Bookingtimeslots.show}
        data={Bookingtimeslots.data}
      />
      <div className="page-content">
        <Container fluid={true}>
          <Card>
            <CardBody>
              <div className="w-25">
                <label>
                  Prenotazioni massime per ombrellone/utente (0 se illimitato){" "}
                </label>
                <InputGroup className="mb-2">
                  <Input
                    type="number"
                    value={maxBookings}
                    onChange={e => setMaxBooking(e.target.value)}
                  />
                  <Button onClick={updateBookingMaster}>Salva</Button>
                </InputGroup>
              </div>
              <Button color="dark" onClick={() => setNewFieldModal(true)}>
                Aggiungi
              </Button>
              <Table>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Sport</th>
                    <th>Durata slot (minuti)</th>
                    <th>Permetti consecutivo</th>
                    <th>Apertura prenotazioni</th>
                    <th>Prenotazioni massime</th>
                    <th>Azioni</th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((v, i) => (
                    <tr key={i}>
                      <td>{v.name}</td>
                      <td>
                        <ul>
                          {v.sports.map(v => (
                            <li key={v.id}>{v.value}</li>
                          ))}
                        </ul>
                      </td>
                      <td>{v.duration} minuti</td>
                      <td>{v.consecutive == 1 ? "Si" : "No"}</td>
                      <td>{v.opening_time_booking}</td>
                      <td>
                        {v.max_bookings_per_umbrella}{" "}
                        {v.booking_order == 1 ? "Per ombrellone" : "Per utente"}
                      </td>
                      <td>
                        <Button
                          onClick={() =>
                            setSlotModals({
                              show: true,
                              data: {
                                slots: v.slots,
                                id: v.id,
                                opening_time_booking: v.opening_time_booking,
                                nocturnal_time: v.nocturnal_time,
                                duration: v.duration,
                                booking_order: v.booking_order,
                                nocturnal_price: v.nocturnal_price,
                                diurnal_price: v.diurnal_price,
                                max_bookings_per_umbrella:
                                  v.max_bookings_per_umbrella,
                              },
                            })
                          }
                          className="m-1"
                        >
                          Modifica fasce
                        </Button>
                        <Button
                          className="m-1"
                          color="primary"
                          onClick={() => handleBookingOpening(v.id)}
                        >
                          Prenotazioni
                        </Button>
                        <Button
                          className="m-1 btn-danger"
                          onClick={() => handleDelete(v.id)}
                        >
                          Elimina
                        </Button>
                        <Button
                          className="m-1 btn-primary"
                          onClick={() =>
                            setFieldModals(e => ({ data: v, show: true }))
                          }
                        >
                          Modifica campo
                        </Button>
                        <Button
                          color={v.enabled == 1 ? "warning" : "primary"}
                          onClick={() => handleDisable(v.id)}
                          className="m-1"
                        >
                          {v.enabled == 1 ? "Disabilita" : "Abilita"}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}
