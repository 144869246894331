import React, { useEffect } from "react"
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  Container,
  Button,
  InputGroup,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useState } from "react"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import {
  getAnagrafica,
  patchAnagrafica,
  getPaymentOptions,
  patchImageAnagrafica,
  getAccountEmail,
  patchAccount,
} from "helpers/fakebackend_helper"
import phonePrefixes from "components/prefixes"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Input } from "reactstrap"

import { getAnagrafica as onGetAnagrafica } from "../../store/anagrafiche/spiaggia/actions"
import axios from "axios"
import { Prompt } from "react-router-dom"
import E from "react-script"

const AnagraficaSpiaggia = () => {
  const handleBeforeUnload = message => {
    // Customize the alert message here
    return `Are you sure you want to leave this page?`
  }
  document.title = "Anagrafica | Skote - React Admin & Dashboard Template"
  const dispatch = useDispatch()

  const [file, setFile] = useState(null)
  const [paymentOptions, setPaymentOptions] = useState([])
  const [initialData, setInitialData] = useState({})
  const [satispayKey, setSatispayKey] = useState("")
  const [IBAN, setIBAN] = useState("")
  const [account, setAccount] = useState({
    email: "",
    password: "",
    id: 0,
    password_confirm: "",
  })
  const [anagraficaData, setAnagraficaData] = useState({
    id: 0,
    name: 0,
    lat: 0,
    lng: 0,
    payments: [],
    max_order_time: 0,
    radius_allowed_in_km: 0,
    address: 0,
    description: 0,
    cap: 0,
    imgurl: 0,
    slider_imgurl: 0,
    to_go_min_order: 0,
    delivery_min_order: 0,
    to_go: 0,
    delivery: 0,
    delivery_price: 0,
    delivery_price_type: 0,
    limit_free_delivery: 0,
    contact_email: 0,
    contact_phone: 0,
    status: 0,
    togo_interval: 0,
    delivery_interval: 0,
    opening_times: 0,
    contact_person: 0,
    long_description: 0,
    delivery_duration: 0,
    vat_cf: 0,
    auto_printer_email: 0,
    max_postpone: 0,
    whatsapp: 0,
    temporary_offline: 0,
    videoURL: 0,
    beach: 0,
    service_charge: 0,
    tm20_printer: 0,
    tm88_printer_1: 0,
    tm88_printer_2: 0,
    tm88_printer_3: 0,
    auto_accept: 0,
    tm88_printer_4: 0,
    tm88_printer_5: 0,
    tm88_printer_6: 0,
    orders_via_whatsapp: 0,
    logo_bg_color: 0,
    is_school: 0,
    status_beach: 0,
    qr: 0,
    field: 0,
    to_print: 0,
    print_field_free_labels: 0,
    print_field_pay_labels: 0,
    print_events_free_labels: 0,
    print_events_pay_labels: 0,
    max_bookings_per_umbrella: 0,
    keyboard_alphanumeric_for_umbrellas: 0,
    activityFrom_day: 0,
    activityFrom_month: 0,
    activityTo_day: 0,
    activityTo_month: 0,
    contact_phone_prefix: "",
    whatsapp_prefix: "",
  })

  const loadAnagrafica = async () => {
    const data = await getAnagrafica()
    setData(data)
    setAnagraficaData(data)
    setInitialData(data)
  }
  const loadPaymentOptions = async () => {
    const data = await getPaymentOptions()
    setPaymentOptions(data)
  }
  const { tags } = useSelector(state => ({
    tags: state.anagrafica.tags,
  }))
  const formattedTags =
    tags.length > 0 ? tags.map(val => ({ value: val.id, label: val.name })) : []

  const { payments } = useSelector(state => ({
    payments: state.anagrafica.payments,
  }))

  const formattedPayments =
    payments.length > 0
      ? payments.map(val => ({ value: val.id, label: val.name }))
      : []

  const [selectedMulti, setSelectedMulti] = useState()
  const [maxDays, setMaxDays] = useState({
    from: 0,
    to: 0,
  })
  const [data, setData] = useState({
    id: 0,
    name: 0,
    lat: 0,
    lng: 0,
    max_order_time: 0,
    radius_allowed_in_km: 0,
    address: 0,
    description: 0,
    cap: 0,
    imgurl: 0,
    slider_imgurl: 0,
    to_go_min_order: 0,
    delivery_min_order: 0,
    to_go: 0,
    delivery: 0,
    delivery_price: 0,
    delivery_price_type: 0,
    limit_free_delivery: 0,
    contact_email: 0,
    contact_phone: 0,
    status: 0,
    togo_interval: 0,
    delivery_interval: 0,
    opening_times: 0,
    contact_person: 0,
    long_description: 0,
    delivery_duration: 0,
    vat_cf: 0,
    auto_printer_email: 0,
    max_postpone: 0,
    whatsapp: 0,
    temporary_offline: 0,
    videoURL: 0,
    beach: 0,
    service_charge: 0,
    tm20_printer: 0,
    tm88_printer_1: 0,
    tm88_printer_2: 0,
    tm88_printer_3: 0,
    auto_accept: 0,
    tm88_printer_4: 0,
    tm88_printer_5: 0,
    tm88_printer_6: 0,
    orders_via_whatsapp: 0,
    logo_bg_color: 0,
    is_school: 0,
    status_beach: 0,
    qr: 0,
    field: 0,
    to_print: 0,
    print_field_free_labels: 0,
    print_field_pay_labels: 0,
    print_events_free_labels: 0,
    print_events_pay_labels: 0,
    max_bookings_per_umbrella: 0,
    keyboard_alphanumeric_for_umbrellas: 0,
    activityFrom_day: 0,
    activityFrom_month: 0,
    activityTo_day: 0,
    activityTo_month: 0,
    contact_phone_prefix: "",
    whatsapp_prefix: "",
  })

  const validateSatispayKey = async () => {}

  const retrieveAccountEmail = async () => {
    const data = await getAccountEmail()
    setAccount({ ...data, password: "", password_confirm: "" })
  }
  useEffect(() => {
    loadAnagrafica()
    loadPaymentOptions()
    retrieveAccountEmail()
  }, [])

  useEffect(() => {
    anagraficaData.data ? setData(anagraficaData.data) : null
  }, [anagraficaData])

  useEffect(() => {
    if (data && data.activityFrom_month) {
      setMaxDays(val => ({
        ...val,
        from: daysInMonth(data.activityFrom_month),
      }))
    }
  }, [data])
  useEffect(() => {
    if (data && data.activityTo_month) {
      setMaxDays(val => ({
        ...val,
        to: daysInMonth(data.activityTo_month),
      }))
    }
  }, [data])

  function daysInMonth(month) {
    const year = new Date().getFullYear()
    return new Date(year, month, 0).getDate()
  }

  const handleSubmit = async () => {
    const res = await patchAnagrafica(data)
    if (res.status) {
      alert("Dati aggiornati")
      loadAnagrafica()
    }
    if (file) {
      let formData = new FormData()
      formData.append("file", file)
      formData.append("id", data.id)
      const resp = await axios.postForm(
        "https://dev2.dasporto.net/newcalls/patchImageAnagrafica.php",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
    }
  }

  const handleUpdateAccount = async () => {
    console.log(account)
    const conf = confirm(
      "Sei sicuro di voler aggiornare i datei per l'accesso?"
    )
    if (!conf) return
    //if password_confirm and password are different return with an alert
    if (account.password_confirm !== account.password) {
      return alert("Le password non coincidono")
    }

    const res = await patchAccount(account)
    if (res.status) {
      alert("Dati aggiornati")
    }
  }

  return (
    <>
      <Prompt
        message="Tutti i dati inseriti non salvati verranno persi, sei sicuro di voler continuare?"
        when={data !== initialData}
      />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Anagrafiche" breadcrumbItem="Spiaggia" />

          <Row>
            <Col xs={12} lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mt-0">Dettagli spiaggia</CardTitle>
                  <CardText>
                    Gestisci qui i dettagli rilevanti alla spiaggia e le varie
                    opzioni di contatto.
                  </CardText>
                </CardBody>
                <CardBody>
                  <Row className="mb-2">
                    <Col>
                      <label>Nome/Ragione sociale</label>
                      <input
                        type="text"
                        value={data.name}
                        onChange={e =>
                          setData(val => ({ ...val, name: e.target.value }))
                        }
                        className="form-control"
                      />
                    </Col>
                    <Col>
                      <label>Email</label>
                      <input
                        type="email"
                        value={data.contact_email}
                        onChange={e =>
                          setData(val => ({
                            ...val,
                            contact_email: e.target.value,
                          }))
                        }
                        className="form-control"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <label>Telefono</label>

                      <div className="row">
                        <div className="col-4">
                          <Select
                            options={phonePrefixes.map(v => ({
                              value: v,
                              label: v,
                            }))}
                            placeholder="Prefisso"
                            value={{
                              label: data.contact_phone_prefix,
                              value: data.contact_phone_prefix,
                            }}
                            onChange={e =>
                              setData(v => ({
                                ...v,
                                contact_phone_prefix: e.value,
                              }))
                            }
                          />
                        </div>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control"
                            value={data.contact_phone}
                            onChange={e =>
                              setData(v => ({
                                ...v,
                                contact_phone: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <label>Whatsapp</label>
                      <div className="row">
                        <div className="col-4">
                          <Select
                            options={phonePrefixes.map(v => ({
                              value: v,
                              label: v,
                            }))}
                            placeholder="Prefisso"
                            value={{
                              label: data.whatsapp_prefix,
                              value: data.whatsapp_prefix,
                            }}
                            onChange={e =>
                              setData(v => ({
                                ...v,
                                whatsapp_prefix: e.value,
                              }))
                            }
                          />
                        </div>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control"
                            value={data.whatsapp}
                            onChange={e =>
                              setData(v => ({
                                ...v,
                                whatsapp: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <label>Referente</label>
                      <input
                        className="form-control"
                        type="text"
                        value={data.contact_person}
                        onChange={e =>
                          setData(v => ({
                            ...v,
                            contact_person: e.target.value,
                          }))
                        }
                      />
                    </Col>
                    <Col>
                      <label>P.IVA/C.F.</label>
                      <input
                        className="form-control"
                        type="text"
                        value={data.vat_cf}
                        onChange={e =>
                          setData(v => ({
                            ...v,
                            vat_cf: e.target.value,
                          }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>
                        Permetti di vedere i campi pubblici degli altri
                        stabilimenti
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={data.allow_public_fields == 1}
                        onClick={e =>
                          e.target.checked
                            ? setData(v => ({ ...v, allow_public_fields: 0 }))
                            : setData(v => ({ ...v, allow_public_fields: 1 }))
                        }
                      />
                    </Col>
                    <Col>
                      <label>
                        Permetti di vedere gli eventi pubblici degli altri
                        stabilimenti
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={data.allow_public_events == 1}
                        onClick={e =>
                          e.target.checked
                            ? setData(v => ({ ...v, allow_public_events: 0 }))
                            : setData(v => ({ ...v, allow_public_events: 1 }))
                        }
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col className="d-flex " xs={12} lg={6}>
              <Card className="w-100">
                <CardBody>
                  <CardTitle className="mt-0">Posizione</CardTitle>
                  <CardText>
                    Gestisci qui i dati relativi alla posizione della spiaggia.
                  </CardText>
                </CardBody>
                <CardBody>
                  <Row className="mb-2">
                    <Col>
                      <label>Indirizzo</label>
                      <input
                        type="text"
                        className="form-control"
                        value={data.address}
                        onChange={e =>
                          setData(v => ({
                            ...v,
                            address: e.target.value,
                          }))
                        }
                      />
                    </Col>
                    <Col>
                      <label>CAP</label>
                      <input
                        type="text"
                        className="form-control"
                        value={data.cap}
                        onChange={e =>
                          setData(v => ({
                            ...v,
                            cap: e.target.value,
                          }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Latitudine</label>
                      <input
                        type="text"
                        className="form-control"
                        value={data.lat}
                        onChange={e =>
                          setData(v => ({
                            ...v,
                            lat: e.target.value,
                          }))
                        }
                      />
                    </Col>
                    <Col>
                      <label>Longitudine</label>
                      <input
                        type="text"
                        className="form-control"
                        value={data.lng}
                        onChange={e =>
                          setData(v => ({
                            ...v,
                            lng: e.target.value,
                          }))
                        }
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6} className=" d-flex">
              <Card className="w-100">
                <CardBody>
                  <CardTitle className="mt-0">Utente</CardTitle>
                  <CardText>Gestisci le tue credenziali.</CardText>
                </CardBody>
                <CardBody>
                  <Row className="mb-2">
                    <Col>
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        value={account.email}
                        onChange={e =>
                          setAccount(v => ({
                            ...v,
                            email: e.target.value,
                          }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <label>Password</label>
                      <Input
                        type="password"
                        placeholder={"******"}
                        onChange={e =>
                          setAccount(v => ({
                            ...v,
                            password: e.target.value,
                          }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <label>Conferma password</label>
                      <Input
                        type="password"
                        placeholder={"******"}
                        onChange={e =>
                          setAccount(v => ({
                            ...v,
                            password_confirm: e.target.value,
                          }))
                        }
                      />
                    </Col>
                  </Row>
                </CardBody>
                <CardBody>
                  <Button color="primary" onClick={handleUpdateAccount}>
                    Aggiorna
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mt-0">Pagamenti</CardTitle>
                  <CardText>
                    Inserisci i pagamenti supportati dell'applicazione
                    (ulterioori dati saranno richiesti per alcuni tipi di
                    pagamento).
                  </CardText>
                </CardBody>
                <CardBody>
                  <Row className="mb-2">
                    <Col xs={12} md={6}>
                      <label>Pagamenti</label>
                      <Select
                        value={data.payments}
                        isMulti={true}
                        onChange={e => setData(v => ({ ...v, payments: e }))}
                        options={paymentOptions.map(v => ({
                          value: v.id,
                          label: v.name,
                        }))}
                      />
                    </Col>
                    <Col>
                      {data.payments &&
                        data.payments.find(v => v.value == 5) !== undefined && (
                          <>
                            <label>Chiave satispay</label>
                            <InputGroup>
                              <Input
                                type="text"
                                value={satispayKey}
                                onChange={e => setSatispayKey(e.target.value)}
                              />
                              <Button onClick={validateSatispayKey}>
                                Valida
                              </Button>
                            </InputGroup>
                          </>
                        )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="mb-4">
                <CardBody>
                  <CardTitle className="mt-0">Apertura stagionale</CardTitle>
                  <CardText></CardText>
                </CardBody>
                <CardBody>
                  <Row className="mb-2">
                    <Col xs={12} md={6}>
                      <label>Da</label>
                      <Row>
                        <Col>
                          <input
                            value={data.activityFrom_day}
                            type="number"
                            onChange={e =>
                              setData(val => ({
                                ...val,
                                activityFrom_day: e.target.value,
                              }))
                            }
                            className="form-control"
                            max={maxDays.from}
                          />
                        </Col>
                        <Col>
                          <select
                            defaultValue={data.activityFrom_month}
                            value={data.activityFrom_month}
                            onChange={e =>
                              setData(val => ({
                                ...val,
                                activityFrom_day: 1,
                                activityFrom_month: e.target.value,
                              }))
                            }
                            className="form-select"
                          >
                            <option value={0}>
                              Seleziona il mese d'inizio stagione
                            </option>
                            <option value={1}>Gennaio</option>
                            <option value={2}>Febbraio</option>
                            <option value={3}>Marzo</option>
                            <option value={4}>Aprile</option>
                            <option value={5}>Maggio</option>
                            <option value={6}>Giugno</option>
                            <option value={7}>Luglio</option>
                            <option value={8}>Agosto</option>
                            <option value={9}>Settembre</option>
                            <option value={10}>Ottobre</option>
                            <option value={11}>Novembre</option>
                            <option value={12}>Dicembre</option>
                          </select>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={6}>
                      <label>A</label>
                      <Row>
                        <Col>
                          <input
                            value={data.activityTo_day}
                            onChange={e =>
                              setData(val => ({
                                ...val,
                                activityTo_day: e.target.value,
                              }))
                            }
                            type="number"
                            className="form-control"
                            min={1}
                            max={maxDays.to}
                          />
                        </Col>
                        <Col>
                          <select
                            className="form-select"
                            value={data.activityTo_month}
                            onChange={e =>
                              setData(val => ({
                                ...val,
                                activityTo_day: 1,
                                activityTo_month: e.target.value,
                              }))
                            }
                          >
                            <option value={0}>
                              Seleziona il mese di fine stagione
                            </option>
                            <option value={1}>Gennaio</option>
                            <option value={2}>Febbraio</option>
                            <option value={3}>Marzo</option>
                            <option value={4}>Aprile</option>
                            <option value={5}>Maggio</option>
                            <option value={6}>Giugno</option>
                            <option value={7}>Luglio</option>
                            <option value={8}>Agosto</option>
                            <option value={9}>Settembre</option>
                            <option value={10}>Ottobre</option>
                            <option value={11}>Novembre</option>
                            <option value={12}>Dicembre</option>
                          </select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="mt-0">Immagine spiaggia</CardTitle>
                  <Row>
                    <Col className="d-flex justify-content-center flex-column mb-3">
                      <div>
                        <img
                          src={data.imgurl}
                          className=" mb-2"
                          style={{ height: "200px" }}
                          alt="Nessun immagine configurata"
                        />

                        <input
                          type="file"
                          style={{ width: "200px" }}
                          onChange={e => setFile(e.target.files[0])}
                          className="form-control"
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="d-flex justify-content-end w-100 mb-2">
            <div className="d-flex ">
              <button className="btn btn-primary" onClick={handleSubmit}>
                Salva modifiche
              </button>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default AnagraficaSpiaggia
