import {
  getFieldBookingsByDate,
  patchTimeslotBooking,
} from "helpers/fakebackend_helper"
import React from "react"
import { useState, useEffect } from "react"
import { BsTrash } from "react-icons/bs"
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

export default function Bookings({ id, show, onHide }) {
  function formatDateForInput(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")

    return `${year}-${month}-${day}`
  }

  const [data, setData] = useState([])
  const [date, setDate] = useState(formatDateForInput(new Date()))
  const loadData = async () => {
    const response = await getFieldBookingsByDate({
      field_id: id,
      date: date,
    })
    setData(response)
  }
  const book = async (id, date) => {
    const reponse = await patchTimeslotBooking({
      timeslot_id: id,
      date: date,
      operation: 1,
    })
    if (reponse.status) {
      alert("Prenotazione bloccata")
      loadData()
    }
  }
  const unBook = async (id, date) => {
    const conf = window.confirm("Sei sicuro di voler eliminare la prenotazione?")
    if (!conf) return
    
    const reponse = await patchTimeslotBooking({
      timeslot_id: id,
      date: date,
      operation: 0,
    })
    if (reponse.status) {
      alert("Prenotazione sbloccata")
      loadData()
    }
  }

  useEffect(() => {
    if (show) {
      loadData()
    }
  }, [id, show, date])

  return (
    <Modal toggle={onHide} isOpen={show}>
      <ModalHeader>Prenotazioni campo</ModalHeader>
      <ModalBody className="d-flex justify-content-center flex-column align-items-center">
        <Input
          type="date"
          value={date}
          onChange={e => {
            setDate(e.target.value)
          }}
        />
        {data &&
          data.timeslots &&
          data.timeslots.map((v, i) => (
            <div
              key={i}
              className="m-2 p-3 bg-light d-flex w-100 flex-column justify-content-center align-items-center"
            >
              <div className="d-flex w-100 justify-content-between">
                <div>
                  <strong>Dalle:</strong> {v.start_slot_time}
                </div>
                <div>
                  <strong>Alle:</strong> {v.end_slot_time}
                </div>
                <div>
                  <strong>Tipo:</strong> {v.type}
                </div>
              </div>
              <div className="d-flex w-100 justify-content-between mt-3">
                <div>
                  <strong>Prezzo:</strong> €{v.price}
                </div>
                <div>
                  <strong>Pagato:</strong> {v.paid == 1 ? "Si" : "No"}
                </div>
                <div>
                  <strong>Stato:</strong>{" "}
                  {!v.booking_id && v.reserved_by_lifeguard !== 1 ? (
                    <Button
                      size="sm"
                      color="warning"
                      onClick={() => book(v.timeslot_id, date)}
                    >
                      Blocca
                    </Button>
                  ) : v.reserved_by_lifeguard === 0 ? (
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => unBook(v.timeslot_id, date)}
                    >
                      Prenotato - elimina
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => unBook(v.timeslot_id, date)}
                    >
                      Sblocca
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ))}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onHide}>Chiudi</Button>
      </ModalFooter>
    </Modal>
  )
}
