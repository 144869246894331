import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get jobs
export const getJobList = () => get(url.GET_JOB_LIST)

// get Apply Jobs
export const getApplyJob = () => get(url.GET_APPLY_JOB)

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// get contacts
export const getUsers = () => get(url.GET_USERS)

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user)

// update user
export const updateUser = user => put(url.UPDATE_USER, user)

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } })

// add jobs
export const addNewJobList = job => post(url.ADD_NEW_JOB_LIST, job)

// update jobs
export const updateJobList = job => put(url.UPDATE_JOB_LIST, job)

// delete jobs
export const deleteJobList = job =>
  del(url.DELETE_JOB_LIST, { headers: { job } })

// Delete Apply Jobs
export const deleteApplyJob = data =>
  del(url.DELETE_APPLY_JOB, { headers: { data } })

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project)

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project)

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } })

export const getUserProfile = () => get(url.GET_USER_PROFILE)

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS)

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail)

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } })

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS)

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS)

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS)

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS)

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS)

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA)
export const getYearlyData = () => get(url.GET_YEARLY_DATA)
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA)

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

//custom
export const getAnagrafica = () => {
  return post(url.GET_ANAGRAFICA, {
    place_id: localStorage.getItem("authUser"),
  })
}
export const getPaymentOptions = () => {
  return get(url.GET_PAYMENT_OPTIONS)
}
export const getTags = () => {
  return get(url.GET_TAGS)
}
export const getUmbrellas = id => {
  return post(url.GET_UMBRELLAS, {
    place_id: localStorage.getItem("authUser"),
  })
}

export const getTables = () => {
  return post(url.GET_TABLES, {
    place_id: localStorage.getItem("authUser"),
  })
}
export const patchFieldTimeslot = data => {
  return post(url.PATCH_FIELD_TIMESLOT, data)
}

export const patchBookingNew = data => {
  return post(url.PATCH_BOOKING_NEW, data)
}
export const addServiceTimeslot = data => {
  return post(url.ADD_TIMESLOT_SERVICE, data)
}
export const updateTimeslot = data => {
  return post(url.ADD_TIMESLOT_FUNCTION, data)
}

export const generateSatispayKey = code => {
  return post(url.GENERATE_SATISPAY_KEY, { code: code })

}
export const deleteTimeslot = data => {
  return post(url.DELETE_TIMESLOT, data)
}
export const deleteTimeslotService = data => {
  return post(url.DELETE_TIMESLOT_SERVICE, data)
}
export const addTimeslotFunction = data => {
  return post(url.ADD_TIMESLOT_FUNCTION, data)
}

export const changeVisibilityField = id => {
  return post(url.CHANGE_VISIBILITY_FIELD, { id, id })
}
export const changeVisibilityService = id => {
  return post(url.CHANGE_VISIBILITY_SERVICE, { id, id })
}
export const loadProducts = data => {
  return post(url.LOAD_PRODUCTS, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const loadSubCategories = () => {
  return post(`${url.LOAD_SUB_CATEGORIES}?order=asc`, {
    place_id: localStorage.getItem("authUser"),
  })
}

export const deleteCategory = id => {
  return post(url.DELETE_CATEGORY, { id: id })
}

export const changeProductStatus = id => {
  return post(url.CHANGE_PRODUCT_STATUS, { id: id })
}

export const patchOrPutSubCategory = data => {
  return post(url.PATCH_OR_PUT_SUB_CATEGORY, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const changeModuleStatus = id => {
  return post(url.CHANGE_MODULE_STATUS, { id: id })
}

export const patchPrintingStatus = data => {
  return post(url.PATCH_PRINTING_STATUS, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const getIngredients = () => {
  return post(url.GET_INGREDIENTS, {
    place_id: localStorage.getItem("authUser"),
  })
}

export const getPrintingStatus = () => {
  return post(url.GET_PRINTING_STATUS, {
    place_id: localStorage.getItem("authUser"),
  })
}

export const getPrettyURLs = () => {
  return post(url.GET_PRETTY_URLS, {
    place_id: localStorage.getItem("authUser"),
  })
}

export const getUm = () => {
  return post(url.GET_UM)
}

export const deleteSingleTimeslot = id => {
  return post(url.DELETE_SINGLE_TIMESLOT, { id: id })
}

export const createVariantWithPrice = data => {
  return post(url.CREATE_VARIANT_WITH_PRICE, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const putExtra = name => {
  return post(url.PUT_EXTRA, { name: name })
}

export const putorPatchProduct = data => {
  return post(url.PUT_OR_PATCH_PRODUCT, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}
export const putPhotoToProduct = data => {
  return post(url.PUT_PHOTO_TO_PRODUCT, data)
}

export const getVariants = () => {
  return post(url.GET_VARIANTS)
}
export const getVariantsWithPrice = () => {
  return post(url.GET_VARIANTS_WITH_PRICE, {
    place_id: localStorage.getItem("authUser"),
  })
}
export const getExtras = () => {
  return post(url.GET_EXTRAS)
}
export const putIngredient = name => {
  return post(url.PUT_INGREDIENT, { name: name })
}
export const putVariant = name => {
  return post(url.PUT_VARIANT, { name: name })
}
export const retrieveSports = () => {
  return post(url.RETRIEVE_SPORTS)
}
export const deleteProduct = id => {
  return post(url.DELETE_PRODUCT, {
    id: id,
    place_id: localStorage.getItem("authUser"),
  })
}
export const getServiceCategories = () => {
  return post(url.GET_SERVICE_CATEGORIES, {
    place_id: localStorage.getItem("authUser"),
  })
}

export const getAllergens = () => {
  return post(url.GET_SERVICE_ALLERGENS)
}

export const putService = data => {
  return post(url.PUT_SERVICE, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const deleteService = id => {
  return post(url.DELETE_SERVICE, {
    id: id,
  })
}
export const retrieveCategories = () => {
  return post(url.RETRIEVE_CATEGORIES)
}

export const updateField = data => {
  return post(url.UPDATE_FIELD, data)
}
export const updateService = data => {
  return post(url.UPDATE_SERVICE, data)
}

export const putField = data => {
  return post(url.PUT_FIELD, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}
export const deleteField = id => {
  return post(url.DELETE_FIELD, { field: id })
}

export const retrieveSportImages = () => {
  return post(url.RETRIEVE_SPORT_IMAGES)
}

export const patchBookingMasterField = number => {
  return post(url.PATCH_BOOKING_MASTER_FIELD, {
    number: number,
    place_id: localStorage.getItem("authUser"),
  })
}
export const loadFields = () => {
  return post(url.LOAD_FIELDS, { place_id: localStorage.getItem("authUser") })
}
export const loadServices = () => {
  return post(url.LOAD_SERVICES, { place_id: localStorage.getItem("authUser") })
}
export const getPriceUmbrellas = data => {
  return post(url.GET_PRICE, data)
}

export const getPricelistData = pricelist_id => {
  return post(url.GET_PRICELIST_DATA, {
    pricelist_id: pricelist_id,
  })
}
export const getElements = () => {
  return post(url.GET_ELEMENTS, {
    place_id: localStorage.getItem("authUser"),
  })
}

export const retrieveEvents = () => {
  return post(url.RETRIEVE_EVENTS, {
    place_id: localStorage.getItem("authUser"),
  })
}

export const getAcceptedPayments = () => {
  return post(url.GET_ACCEPTED_PAYMENTS, {
    place_id: localStorage.getItem("authUser"),
  })
}

export const getTypes = () => {
  return post(url.GET_TYPES, {
    place_id: localStorage.getItem("authUser"),
  })
}
export const getPricelist = () => {
  return post(url.GET_PRICELIST_ID, {
    place_id: localStorage.getItem("authUser"),
  })
}
export const patchType = data => {
  return post(url.PATCH_TYPE, data)
}
export const putType = data => {
  return post(url.PUT_TYPE, data)
}
export const patchSeason = data => {
  return post(url.PATCH_SEASON, data)
}
export const patchSeasonNew = data => {
  return post(url.PATCH_SEASON_NEW, data)
}

export const putNewSeason = data => {
  return post(url.PUT_NEW_SEASON, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const putDeposit = data => {
  return post(url.PUT_DEPOSIT, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const deleteBookingFromBackend = booking_id => {
  return post(url.DELETE_BOOKING, { id: booking_id })
}
export const getDeposit = booking_id => {
  return post(url.GET_DEPOSIT, { booking_id: booking_id })
}
export const putSeason = data => {
  return post(url.PUT_SEASON, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}
export const deleteSeason = id => {
  return post(url.DELETE_SEASON, { id: id })
}
export const putPricelist = name => {
  return post(url.PUT_PRICELIST, {
    name: name,
    place_id: localStorage.getItem("authUser"),
  })
}
export const putPricelistData = (tab, pricelist_id) => {
  return post(url.PUT_PRICELIST_DATA, {
    tab,
    pricelist_id: pricelist_id,
  })
}
export const patchAnagrafica = data => {
  return post(url.PATCH_ANAGRAFICA, data)
}
export const getImages = () => {
  return post(url.GET_IMAGES)
}

export const getUmbrellasForGantt = () => {
  return post(url.GET_UMBRELLAS_FOT_GANTT, {
    place_id: localStorage.getItem("authUser"),
  })
}

export const getCabins = id => {
  return post(url.GET_CABINS, {
    place_id: id,
  })
}
export const getDefaultElements = () => {
  return post(url.GET_DEFAULT_ELEMENTS, {
    place_id: localStorage.getItem("authUser"),
  })
}
export const putElements = data => {
  return post(url.PUT_DEFAULT_ELEMENTS, {
    data: data,
    place_id: localStorage.getItem("authUser"),
  })
}
export const putBooking = data => {
  return post(url.PUT_BOOKING, data)
}

export const getFieldPaymentMethods = () => {
  return post(url.GET_FIELD_PAYMENT_METHODS, {
    place_id: localStorage.getItem("authUser"),
  })
}

export const getServicePaymentMethods = () => {
  return post(url.GET_SERVICE_PAYMENT_METHODS, {
    place_id: localStorage.getItem("authUser"),
  })
}

export const patchServicePayments = data => {
  return post(url.PATCH_SERVICE_PAYMENTS, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const patchMenuVisibility = data => {
  return post(url.PATCH_MENU_VISIBILITY, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const patchFieldPayments = data => {
  return post(url.PATCH_FIELD_PAYMENTS, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const putSector = name => {
  return post(url.PUT_SECTOR, {
    name: name,
    place_id: localStorage.getItem("authUser"),
  })
}
export const getDefaultDisplacements = () => {
  return post(url.GET_DEFAULT_TYPES, {
    place_id: localStorage.getItem("authUser"),
  })
}
export const getPlaceDimensions = () => {
  return post(url.GET_DIMENSIONS, {
    place_id: localStorage.getItem("authUser"),
  })
}
export const createCode = () => {
  return post(url.GET_CODE, {
    place_id: localStorage.getItem("authUser"),
  })
}
export const createUmbrellaFromBackend = (
  number,
  qrcode,
  max_customers,
  table
) => {
  return post(url.CREATE_UMBRELLA_BACKEND, {
    place_id: localStorage.getItem("authUser"),
    number: number,
    qrcode: qrcode,
    max_customers: max_customers,
    table: table,
  })
}

export const isUserOwningModule = module => {
  return post(url.IS_USER_OWNING_MODULE, {
    module: module,
    place_id: localStorage.getItem("authUser"),
  })
}

export const getPlacePlan = plan => {
  return post(url.GET_PLACE_PLAN, {
    place_id: localStorage.getItem("authUser"),
    plan: plan,
  })
}

export const patchTimeslotBooking = data => {
  return post(url.PATCH_TIMESLOT_BOOKING, data)
}

export const patchMenu = data => {
  return post(url.PATCH_MENU, data)
}

export const calculateAvailability = data => {
  return post(url.CALCULATE_AVAILABILITY, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const updateVariantsOrdering = data => {
  return post(url.UPDATE_VARIANTS_ORDERING, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const deleteProductVariant = data => {
  return post(url.DELETE_PRODUCT_VARIANT, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const appendProductVariant = data => {
  return post(url.APPEND_PRODUCT_VARIANT, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const updateCategoryOrdering = data => {
  return post(url.UPDATE_CATEGORY_ORDERING, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const getPopupInfos = () => {
  return post(url.GET_POPUP_INFOS, {
    place_id: localStorage.getItem("authUser"),
  })
}

export const resetCredentials = data => {
  return post(url.RESET_CREDENTIALS, data)
}

export const checkBackCredentials = token => {
  return post(url.CHECK_BACK_CREDENTIALS, { token: token })
}
export const getFieldBookingsByDate = data => {
  return post(url.GET_FIELD_BOOKINGS_BY_DATE, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const patchPopup = data => {
  data.append("place_id", localStorage.getItem("authUser"))
  return post(url.PATCH_POPUP, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export const patchCredentials = data => {
  return post(url.PATCH_CREDENTIALS, data)
}

export const appendExtras = data => {
  return post(url.APPEND_EXTRAS, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const updateExtrasOrdering = data => {
  return post(url.UPDATE_EXTRAS_ORDERING, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const patchUmbrellaFromBackend = (number, qrcode, max_customers, id) => {
  return post(url.PATCH_UMBRELLA_BACKEND, {
    place_id: localStorage.getItem("authUser"),
    number: number,
    qrcode: qrcode,
    max_customers: max_customers,
    id: id,
  })
}

export const createMultipleQR = (from, to, clients, table) => {
  return post(url.CREATE_MULTIPLE_QR, {
    from: from,
    to: to,
    clients: clients,
    place_id: localStorage.getItem("authUser"),
    table: table,
  })
}

export const deleteExtraBooking = id => {
  return post(url.DELETE_EXTRA_BOOKING, {
    id: id,
  })
}

export const getAccountEmail = () => {
  return post(url.GET_ACCOUNT_EMAIL, {
    place_id: localStorage.getItem("authUser"),
  })
}

export const patchAccount = data => {
  return post(url.PATCH_ACCOUNT, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const deleteUmbrellaFromBackend = id => {
  return post(url.DELETE_UMBRELLA_BACKEND, { id: id })
}
export const getSeasons = () => {
  return post(url.GET_SEASONS, {
    place_id: localStorage.getItem("authUser"),
  })
}
export const getSeasonsNew = () => {
  return post(url.GET_SEASONS_NEW, {
    place_id: localStorage.getItem("authUser"),
  })
}
export const putDefaultTypes = data => {
  return post(url.PUT_DEFAULT_TYPES, {
    data: data,
    place_id: localStorage.getItem("authUser"),
  })
}
export const getPricelists = () => {
  return post(url.GET_PRICELIST, { place_id: localStorage.getItem("authUser") })
}
export const getSectors = () => {
  return post(url.GET_SECTORS, { place_id: localStorage.getItem("authUser") })
}
export const patchSector = data => {
  return post(url.PATCH_SECTOR, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}
export const getFictionalCustomers = () => {
  return post(url.GET_FICTIONAL_CUSTOMERS, {
    place_id: localStorage.getItem("authUser"),
  })
}
export const deleteSector = id => {
  return post(url.DELETE_SECTOR, {
    id: id,
  })
}
export const shouldSendAlert = () => {
  return post(url.GET_SHOULD_SEND_ALERT, {
    place_id: localStorage.getItem("authUser"),
  })
}

export const putDisplacement = data => {
  return post(url.PUT_DISPLACEMENT, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const getActivityDates = () => {
  return post(url.GET_ACTIVITY_DATES, {
    place_id: localStorage.getItem("authUser"),
  })
}

export const assignCustomerToBooking = (bid, fuid) => {
  return post(url.ASSIGN_CUSTOMER_TO_BOOKING, {
    booking_id: bid,
    user_id: fuid,
  })
}

export const deleteBookingAssociation = id => {
  return post(url.DELETE_BOOKING_ASSOCIATION, { id: id })
}

export const patchDisplacement = data => {
  return post(url.PATCH_DISPLACEMENT, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const deleteDisplacement = id => {
  return post(url.DELETE_DISPLACEMENT, {
    id: id,
    place_id: localStorage.getItem("authUser"),
  })
}

export const getCustomersToAssociate = () => {
  return post(url.GET_CUSTOMERS_TO_ASSOCIATE, {
    place_id: localStorage.getItem("authUser"),
  })
}
export const patchBookingBackend = data => {
  return post(url.PATCH_BOOKING_BACKEND, {
    ...data,
    place_id: localStorage.getItem("authUser"),
  })
}

export const patchImageAnagrafica = form => {
  return post(url.PATCH_IMAGE_ANAGRAFICA, form, {
    Headers: { "Content-Type": "multipart/form-data" },
  })
}
export const getModules = () => {
  return post(url.GET_MODULES, {
    place_id: localStorage.getItem("authUser"),
  })
}

export const getAllModules = () => {
  return post(url.GET_MODULES + "?all=true", {
    place_id: localStorage.getItem("authUser"),
  })
}

//end custom
const getProductComents = () => get(url.GET_PRODUCT_COMMENTS)

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  })
}
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  })
}

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  })
}

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  })
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
}
