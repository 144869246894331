import {
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import React, { useRef, useState, useEffect } from "react"
import {
  Container,
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { BsTrashFill } from "react-icons/bs"
import axios from "axios"
import { singleQuestions, groupQuestions } from "./questions_template"
import {
  getModules,
  retrieveEvents,
  getAcceptedPayments,
} from "helpers/fakebackend_helper"
import Select from "react-select"

export default function ModalAdd({ show, onHide, id, data, handleUpdate }) {
  console.log(id)
  const fileInputRef = useRef(null)
  const [groupValue, setGroupValue] = useState(1)
  const [image, setImage] = useState(false)
  const [imageName, setImageName] = useState(false)
  const [templateQuestions, setTemplateQuestions] = useState(1)
  const [modules, setModules] = useState([])
  const [tickets, setTickets] = useState([
    {
      name: "",
      price: "",
      description: "",
      qty: "",
    },
  ])
  const [selectedPayments, setSelectedPayments] = useState([])
  const [acceptedPayments, setAcceptedPayments] = useState([])
  const loadModules = async () => {
    const data = await getModules()
    setModules(data)
  }

  const loadAcceptedPayments = async () => {
    const data = await getAcceptedPayments()
    setAcceptedPayments(data)
  }
  useEffect(() => {
    loadAcceptedPayments()
  }, [])
  useEffect(() => {
    const c = formik.resetForm()
    console.log(c)
    setImage(false)
    setImageName(false)
    setSelectedPayments([])
    setTickets([
      {
        name: "",
        price: "",
        description: "",
        qty: "",
      },
    ])
    setQuestions([
      {
        question: "",
        type: "", //can be either select, text or number
        options: [
          {
            name: "",
          },
        ], // only if type is select
      },
    ])
  }, [show])
  useEffect(() => {
    formik.resetForm()
  }, [show])
  useEffect(() => {
    //if there is an id, it means we are editing an event, so we need to fetch the data and set it in the form, aswell as in tickets and questions
    if (id) {
      const formData = new FormData()
      formData.append("id", id)
      /* const data = await retrieveEvents() */
      console.log(data)
      //set the formik values
      formik.setValues({
        title: data.title,
        max_participants: data.participants,
        description: data.description,
        from: data.from,
        public: data.public,
        bookable_from: data.bookable_from,
        bookable_to: data.bookable_to,
        from: data.date_from,
        tournament: data.tournament == 1 ? true : false,
        to: data.date_to,
        starts_at: data.starts_at,
        ends_at: data.ends_at,
        referral: data.referral,
        max_participants_per_user: data.participants_per_user,
        referral_email: data.referral_email,
        referral_phone: data.referral_phone,
      })
      //set the image
      setImage(data.image)
      //set the tickets
      setTickets(data.tickets)
      //set the questions
      setQuestions(data.questions)

      setSelectedPayments(
        data.payments.map(v => ({ value: v.id, label: v.name }))
      )

      setGroupValue(data.group_value)

      if (data.group_value > 1) {
        setTemplateQuestions(2)
      }
    } else {
      formik.resetForm()
    }
  }, [id, show])

  const [questions, setQuestions] = useState([
    {
      question: "",
      type: "", //can be either select, text or number
      options: [
        {
          name: "",
        },
      ], // only if type is select
    },
  ])

  const handleOrdering = () => {
    const newQuestions = [...questions]

    newQuestions.sort((a, b) => {
      const regex = /\d+/g // Regular expression to match numbers in the string
      const aNumberMatch = a.question.match(regex) // Extract numbers from string a.question
      const bNumberMatch = b.question.match(regex) // Extract numbers from string b.question

      // Handle cases where there are no numbers in the question
      if (!aNumberMatch || !bNumberMatch) {
        // Place non-number questions at the beginning
        if (!aNumberMatch) {
          return -1
        } else if (!bNumberMatch) {
          return 1
        }
      }

      // Extract the first number from the matched strings
      const aNumber = parseInt(aNumberMatch[0], 10)
      const bNumber = parseInt(bNumberMatch[0], 10)

      return aNumber - bNumber
    })

    const newQuestionsWithOrdering = newQuestions.map((v, i) => {
      return {
        ...v,
        ordering: i,
      }
    })
    setQuestions(newQuestionsWithOrdering)
  }
  useEffect(() => {
    if (groupValue > 1) {
      setTemplateQuestions(2)
    } else {
      setTemplateQuestions(1)
    }
  }, [groupValue])
  useEffect(() => {
    if (templateQuestions == 2 && groupValue == 1) {
      setGroupValue(2)
    }
  }, [templateQuestions])
  useEffect(() => {
    console.log(questions)
  }, [questions])
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Il titolo è obbligatorio"),
    max_participants: Yup.number().required(
      "Il numero massimo di partecipanti è obbligatorio"
    ),
    description: Yup.string().required("La descrizione è obbligatoria"),
    //bookable_to, from, to and bookable_from are required

    bookable_from: Yup.date().required(
      "La data di inizio prenotazione è obbligatoria"
    ),
    bookable_to: Yup.date().required(
      "La data di fine prenotazione è obbligatoria"
    ),
    from: Yup.date().required("La data di inizio evento è obbligatoria"),
    to: Yup.date().required("La data di fine evento è obbligatoria"),
  })
  const formik = useFormik({
    initialValues: {
      title: "",
      max_participants: "",
      description: "",
      tournament: false,
      from: "",
      to: "",
      starts_at: "",
      ends_at: "",
      bookable_from: "",
      bookable_to: "",
      public: false,
      referral: "",
      referral_email: "",
      referral_phone: "",
      max_participants_per_user: "",
      referral_email: "",
      referral_phone: "",
      bookable_from: "",
      bookable_to: "",
    },
    validationSchema,
    onSubmit: async values => {
      //prepare formData to send, make sure to correctly include the image
      let fmd = new FormData()
      if (id) {
        fmd.append("id", id)
      }
      fmd.append("image", fileInputRef.current.files[0] || image)
      fmd.append("title", values.title)
      fmd.append("max_participants", values.max_participants)
      fmd.append("description", values.description)
      fmd.append("from", values.from)
      fmd.append("to", values.to)
      fmd.append("bookable_from", values.bookable_from)
      fmd.append("bookable_to", values.bookable_to)
      fmd.append("public", values.public ? 1 : 0)
      fmd.append("tournament", values.tournament ? 1 : 0)
      fmd.append("group_value", groupValue)
      fmd.append("starts_at", values.starts_at)
      fmd.append("ends_at", values.ends_at)
      fmd.append("referral", values.referral)
      fmd.append("referral_email", values.referral_email)
      fmd.append("referral_phone", values.referral_phone)
      fmd.append("max_participants_per_user", values.max_participants_per_user)

      tickets.forEach((ticket, index) => {
        if (ticket.id) {
          fmd.append(`tickets[${index}][id]`, ticket.id)
        }

        fmd.append(`tickets[${index}][name]`, ticket.name)
        fmd.append(`tickets[${index}][price]`, ticket.price)
        fmd.append(`tickets[${index}][description]`, ticket.description)
        fmd.append(`tickets[${index}][qty]`, ticket.qty)
        fmd.append("place_id", localStorage.getItem("authUser"))
      })
      questions.forEach((question, index) => {
        if (question.id) {
          fmd.append(`questions[${index}][id]`, question.id)
        }
        fmd.append(`questions[${index}][question]`, question.question)
        fmd.append(`questions[${index}][ordering]`, question.ordering)
        fmd.append(`questions[${index}][type]`, question.type)
        question.options &&
          question.options.forEach((option, i) => {
            fmd.append(
              `questions[${index}][options][${i}][option]`,
              option.option
            )
          })
      })
      selectedPayments.forEach((payment, index) => {
        fmd.append(`payments[${index}][id]`, payment.value)
      })

      const { data } = await axios.post(
        "https://dev2.dasporto.net/newcalls/putOrPatchEvent.php",
        fmd
      )
      if (data.status) {
        if (id) {
          alert("Evento modificato")
        } else {
          alert("Evento creato")
        }
        await handleUpdate()
        onHide()
      }
    },
  })

  const handleButtonClick = () => {
    fileInputRef.current && fileInputRef.current.click()
  }
  const handleFileChange = event => {
    const file = event.target.files[0]
    console.log(file)

    //set a temporary URL and place is in the image state
    setImage(URL.createObjectURL(file))
    setImageName(file.name)
  }

  return (
    <Modal isOpen={show} toggle={onHide} size="xl">
      <form onSubmit={formik.handleSubmit}>
        <ModalHeader toggle={onHide}>Aggiungi evento</ModalHeader>
        <ModalBody>
          <h3>Informazioni generiche</h3>
          <Row>
            <Col>
              <label>Nome dell'evento</label>
              <Input
                type="text"
                name="title"
                placeholder="Nome dell'evento"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.title && formik.errors.title && (
                <div className="error">{formik.errors.title}</div>
              )}
            </Col>
            <Col xs={3}>
              <label>Numero massimo partecipanti</label>
              <Input
                type="number"
                name="max_participants"
                placeholder="Numero massimo partecipanti"
                value={formik.values.max_participants}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.max_participants &&
                formik.errors.max_participants && (
                  <div className="error">{formik.errors.max_participants}</div>
                )}
            </Col>
            <Col xs={3}>
              <label>n. massimo partecipanti per utente</label>
              <Input
                type="number"
                name="max_participants_per_user"
                placeholder="Numero massimo partecipanti per utente"
                value={formik.values.max_participants_per_user}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.max_participants_per_user &&
                formik.errors.max_participants_per_user && (
                  <div className="error">
                    {formik.errors.max_participants_per_user}
                  </div>
                )}
            </Col>
            <Col xs={3} className="d-flex flex-column ">
              <label>&nbsp;</label>
              <div>
                <div>
                  <Input
                    type="checkbox"
                    name="public"
                    value={formik.values.public}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="mx-1"
                  />
                  <label className="mb-0">Evento pubblico</label>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <label>Referente</label>
              <Input
                type="text"
                name="referral"
                placeholder="Nome referente"
                value={formik.values.referral}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
            <Col>
              <label>Email</label>
              <Input
                type="email"
                name="referral_email"
                placeholder="Email referente"
                value={formik.values.referral_email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
            <Col>
              <label>Telefono</label>
              <Input
                type="text"
                name="referral_phone"
                placeholder="Telefono referente"
                value={formik.values.referral_phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <label>Descrizione</label>
              <textarea
                className="form-control"
                name="description"
                rows="2"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.description && formik.errors.description && (
                <div className="error">{formik.errors.description}</div>
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={6} className="d-flex flex-column">
              <label>Foto</label>
              {image ? (
                <img
                  src={image}
                  alt="Foto"
                  style={{ objectFit: "cover", maxHeight: "200px" }}
                  className="rounded"
                />
              ) : (
                <div
                  className="bg-light w-100 d-flex justify-content-center align-items-center rounded"
                  style={{ height: "120px" }}
                >
                  Nessun immagine inserita
                </div>
              )}
              <div>
                <input
                  type="button"
                  value={"Carica"}
                  className="btn btn-primary mt-2"
                  onClick={handleButtonClick}
                />
                {image && (
                  <input
                    type="button"
                    value={"Elimina"}
                    className="btn btn-secondary mt-2 mx-1"
                    onClick={() => {
                      setImage(false)
                    }}
                  />
                )}
                <input
                  type="file"
                  name="image"
                  ref={fileInputRef}
                  className="d-none"
                  onChange={event => {
                    handleFileChange(event)
                    formik.handleChange(event)
                  }}
                  value={formik.values.image}
                  onBlur={formik.handleBlur}
                />
              </div>
            </Col>
            <Col xs={3}>
              <label>Pagamenti</label>
              <Select
                isMulti
                value={selectedPayments}
                onChange={e => {
                  setSelectedPayments(e)
                }}
                options={acceptedPayments.map(v => ({
                  value: v.id,
                  label: v.name,
                }))}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <label>Prenotazione disponibile da</label>
              <Input
                type="datetime-local"
                name="bookable_from"
                value={formik.values.bookable_from}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
            <Col>
              <label>Prenotazione disponibile a</label>
              <Input
                type="datetime-local"
                name="bookable_to"
                value={formik.values.bookable_to}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <label>Dal giorno</label>
              <Input
                type="date"
                name="from"
                placeholder="Da"
                value={formik.values.from}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
            <Col>
              <label>Al giorno</label>
              <Input
                type="date"
                name="to"
                placeholder="A"
                value={formik.values.to}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
            <Col>
              <label>Orari da:</label>
              <Input
                type="time"
                name="starts_at"
                placeholder="Da"
                value={formik.values.starts_at}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
            <Col>
              <label>Orari a:</label>
              <Input
                type="time"
                name="ends_at"
                placeholder="A"
                value={formik.values.ends_at}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
          </Row>
          <h3 className="mt-4">Biglietti</h3>
          {tickets.map((ticket, index) => {
            return (
              <Row key={index} className="mb-2">
                <Col xs={3}>
                  <label>Titolo</label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Titolo"
                    value={ticket.name}
                    onChange={e => {
                      const newTickets = [...tickets]
                      newTickets[index].name = e.target.value
                      setTickets(newTickets)
                    }}
                  />
                </Col>
                <Col xs={3}>
                  <label>Descrizione</label>
                  <Input
                    type="textarea"
                    name="description"
                    placeholder="Es. la quota comprende..."
                    value={ticket.description}
                    onChange={e => {
                      const newTickets = [...tickets]
                      newTickets[index].description = e.target.value
                      setTickets(newTickets)
                    }}
                  />
                </Col>
                <Col xs={2}>
                  <label>Prezzo</label>
                  <InputGroup>
                    <InputGroupText>€</InputGroupText>
                    <Input
                      type="number"
                      name="price"
                      value={ticket.price || 0}
                      onChange={e => {
                        const newTickets = [...tickets]
                        newTickets[index].price = e.target.value
                        setTickets(newTickets)
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col xs={2}>
                  <label>Qta (0 per illimitata)</label>
                  <Input
                    type="number"
                    name="qty"
                    value={ticket.qty || 0}
                    onChange={e => {
                      const newTickets = [...tickets]
                      newTickets[index].qty = e.target.value
                      setTickets(newTickets)
                    }}
                  />
                </Col>
                <Col xs={2} className="d-flex flex-column">
                  <label>&nbsp;</label>
                  <div className="d-flex ">
                    <Button
                      color="danger"
                      className="mx-1"
                      onClick={() => {
                        //splice only if there are more than one ticket left, otherwise just reset the values
                        if (tickets.length > 1) {
                          const newTickets = [...tickets]
                          newTickets.splice(index, 1)
                          setTickets(newTickets)
                        }
                        //if there is only one ticket left, just reset the values
                        else {
                          setTickets([
                            {
                              name: "",
                              price: "",
                              description: "",
                              qty: "",
                            },
                          ])
                        }
                      }}
                    >
                      Elimina
                    </Button>
                    {index === tickets.length - 1 && (
                      <Button
                        className="mx-1"
                        onClick={() => {
                          const newTickets = [...tickets]
                          newTickets.push({
                            name: "",
                            price: "",
                            description: "",
                            qty: "",
                          })
                          setTickets(newTickets)
                        }}
                      >
                        Aggiungi
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            )
          })}
          <h3 className="mt-2">Domande</h3>
          <Button onClick={handleOrdering}>Ordina</Button>

          {questions.map((v, i) => {
            return (
              <>
                <Row key={i} className="mt-2">
                  <Col xs={3}>
                    <label>Domanda</label>
                    <Input
                      type="text"
                      name="question"
                      placeholder="Domanda"
                      value={v.question}
                      onChange={e => {
                        const newQuestions = [...questions]
                        newQuestions[i].question = e.target.value
                        setQuestions(newQuestions)
                      }}
                    />
                  </Col>
                  <Col xs={3}>
                    <label>Tipologia</label>
                    <select
                      className="form-select"
                      onChange={e => {
                        const newQuestions = [...questions]
                        newQuestions[i].type = e.target.value

                        setQuestions(newQuestions)
                      }}
                    >
                      <option value="text">Testo</option>
                      <option value="number">Numero</option>
                      <option value="select">Selezione</option>
                      <option value="textarea">Area di testo</option>
                    </select>

                    {v.type === "select" && v.options && (
                      <>
                        <label>Opzioni</label>
                        {v.options.map((option, index) => {
                          return (
                            <>
                              <Row>
                                <Col>
                                  <InputGroup className="mb-1">
                                    <Input
                                      placeholder={`Opzione ${index + 1}`}
                                      value={option.option}
                                      onChange={e => {
                                        const newQuestions = [...questions]
                                        newQuestions[i].options[index].option =
                                          e.target.value
                                        setQuestions(newQuestions)
                                      }}
                                    />
                                    {index === v.options.length - 1 && (
                                      <input
                                        type="button"
                                        value={"+"}
                                        className="btn btn-primary "
                                        onClick={() => {
                                          const newQuestions = [...questions]
                                          newQuestions[i].options.push({
                                            option: "",
                                          })
                                          setQuestions(newQuestions)
                                        }}
                                      />
                                    )}
                                    {
                                      //only show the delete button if there are more than 1 record
                                      v.options.length > 1 && (
                                        <input
                                          type="button"
                                          value={"-"}
                                          className="btn btn-danger "
                                          onClick={() => {
                                            const newQuestions = [...questions]
                                            newQuestions[i].options.splice(
                                              index,
                                              1
                                            )
                                            setQuestions(newQuestions)
                                          }}
                                        />
                                      )
                                    }
                                  </InputGroup>
                                </Col>
                              </Row>
                            </>
                          )
                        })}
                      </>
                    )}
                  </Col>
                  <Col xs={3} className="d-flex flex-column">
                    <label>&nbsp;</label>
                    <div className="d-flex">
                      <input
                        type="button"
                        value={"Elimina"}
                        className="btn btn-danger mb-2 mx-2"
                        onClick={() => {
                          if (questions.length > 1) {
                            const newQuestions = [...questions]
                            newQuestions.splice(i, 1)
                            setQuestions(newQuestions)
                          }

                          //if there is only one ticket left, just reset the values
                          else {
                            setQuestions([
                              {
                                question: "",
                                type: "", 
                                options: [
                                  {
                                    option: "",
                                  },
                                ], // only if type is select
                              },
                            ])
                          }
                        }}
                      />
                      <input
                        type="button"
                        value={"Aggiungi"}
                        className="btn btn-secondary mb-2"
                        onClick={() => {
                          const newQuestions = [...questions]
                          newQuestions.push({
                            question: "",
                            type: "text",
                            options: [
                              {
                                option: "",
                              },
                            ],
                          })
                          setQuestions(newQuestions)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )
          })}
          <div
            style={{ height: "1px", backgroundColor: "black", opacity: "30%" }}
            className="my-3"
          />
          <div>
            <Input
              type="checkbox"
              name="tournament"
              checked={formik.values.tournament}
              onClick={e => {
                //set the tournament value based on the checked property of the element
                formik.setValues({
                  ...formik.values,
                  tournament: !e.target.checked,
                })
              }}
              onBlur={formik.handleBlur}
              className="mx-1"
            />
            <label className="mb-0">Torneo</label>
          </div>
          {formik.values.tournament && (
            <div className="d-flex mt-2">
              <div>
                <Input
                  type="checkbox"
                  className="mx-1"
                  onClick={() => {
                    setTemplateQuestions(1)
                  }}
                  checked={templateQuestions === 1}
                />
                <label>Singoli</label>
              </div>
              <div className="px-2"></div>
              <div className="d-flex">
                <Input
                  type="checkbox"
                  className="mx-1"
                  onClick={() => {
                    setTemplateQuestions(2)
                  }}
                  checked={templateQuestions === 2}
                />
                <label>Per gruppo</label>
                {templateQuestions == 2 && (
                  <input
                    type="number"
                    min={2}
                    value={groupValue}
                    onChange={e => setGroupValue(e.target.value)}
                    className="mx-1"
                    style={{ width: "40px", height: "90%" }}
                  />
                )}
              </div>
            </div>
          )}
          {formik.values.tournament == 1 && (
            <Row>
              {
                //if templateQuestions is 1, show the single questions, otherwise show the group questions, it needs to be a checkbox that either appends or remove the question to the questions array, it should only remove the question if the questions.lenght is > 1
                templateQuestions === 1
                  ? singleQuestions.map(
                      (v, i) => {
                        return (
                          <Col xs={3} key={i}>
                            <Input
                              checked={
                                questions.findIndex(
                                  question => question.question === v.question
                                ) !== -1
                              }
                              type="checkbox"
                              className="mx-1"
                              onClick={() => {
                                let newQuestions = questions
                                if (
                                  questions.findIndex(
                                    question => question.question === v.question
                                  ) !== -1
                                ) {
                                  newQuestions = newQuestions.filter(
                                    question => question.question !== v.question
                                  )
                                } else {
                                  newQuestions = [
                                    ...questions,
                                    { ...v, id: null },
                                  ]
                                }
                                setQuestions(newQuestions)
                              }}
                            />
                            <label>{v.question}</label>
                          </Col>
                        )
                      }
                      //if templateQuestions is 2, show the group questions
                    )
                  : groupQuestions.map((v, i) => {
                      return (
                        <Col xs={3} key={i}>
                          <Input
                            type="checkbox"
                            checked={
                              questions.findIndex(
                                question => question.original === v.question
                              ) !== -1
                            }
                            className="mx-1"
                            onClick={e => {
                              let newQuestions = questions
                              if (
                                questions.findIndex(
                                  question =>
                                    question.question === v.question ||
                                    question.original === v.question
                                ) !== -1
                              ) {
                                console.log("ciao")
                                console.log("prova ", v.question)
                                newQuestions = newQuestions.filter(question => {
                                  console.log(
                                    "prova2 ",

                                    question.original == v.question
                                  )
                                  return (
                                    question.question != v.question &&
                                    question.original != v.question
                                  )
                                })
                                console.log("ciao ", newQuestions)
                              } else {
                                /* console.log(groupValue) */
                                let array = []
                                for (let i = 0; i < groupValue; i++) {
                                  array.push({
                                    ...v,
                                    question: `${v.question} ${i + 1}`,
                                    original: v.question,
                                  })
                                }
                                newQuestions = [...questions, ...array]
                              }
                              setQuestions(newQuestions)
                            }}
                          />
                          <label>{v.question}</label>
                        </Col>
                      )
                    })
              }
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <input type="submit" className="btn btn-primary" />
        </ModalFooter>
      </form>
    </Modal>
  )
}
