//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/loginBackend.php"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

// JOBS
export const GET_JOB_LIST = "/jobs"
export const ADD_NEW_JOB_LIST = "/add/job"
export const UPDATE_JOB_LIST = "/update/job"
export const DELETE_JOB_LIST = "/delete/job"

//Apply Jobs
export const GET_APPLY_JOB = "/jobApply"
export const DELETE_APPLY_JOB = "add/applyjob"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"

//custom

export const GET_ANAGRAFICA = "/planner/getAnagrafica.php"

export const GET_PAYMENT_OPTIONS = "/planner/getPaymentCategories.php"

export const GET_TAGS = "/planner/getCategories.php"

export const GET_UMBRELLAS = "/planner/getUmbrellasByPlace.php"

export const GET_TABLES = "getTables.php"

export const GET_PRICE = "/getUmbrellaCostsNew.php"

export const GET_PRICELIST_DATA = "/getPricelistData.php"

export const GET_PRICELIST_ID = "/getPricelist.php"

export const GET_ELEMENTS = "/getElements.php"

export const GET_CABINS = "/getCabins.php"

export const PUT_BOOKING = "/createBookingFromBackendNew.php"

export const GET_TYPES = "/planner/getTypes.php?include_environment=1"

export const GET_SECTORS = "/planner/getSectors.php"

export const PUT_SECTOR = "/putSector.php"

export const GET_PRICELIST = "/planner/getPricelistsByPlaceId.php"

export const GET_FICTIONAL_CUSTOMERS = "/planner/getFictionalCustomers.php"

export const GET_IMAGES = "retrieveImages.php"

export const PATCH_TYPE = "patchTypes.php"

export const PATCH_SECTOR = "patchSector.php"

export const PUT_TYPE = "putTypes.php"

export const GET_SEASONS = "getSeasons.php"

export const GET_SEASONS_NEW = "getSeasonsNew.php"

export const GET_DEFAULT_TYPES = "getDefaultTypes.php"

export const PUT_DEFAULT_TYPES = "putDefaultTypes.php"

export const PUT_PRICELIST = "putPricelist.php"

export const PUT_PRICELIST_DATA = "putPricelistData.php"

export const PUT_SEASON = "planner/createSeason.php"

export const PUT_ELEMENT = "putElement.php"

export const GENERATE_SATISPAY_KEY = "generateSatispayKey2.php"

export const PATCH_SEASON = "/planner/patchSeason.php"

export const PATCH_SEASON_NEW = "/planner/patchSeasonNew.php"

export const DELETE_SEASON = "/planner/deleteSeason.php"

export const PATCH_ANAGRAFICA = "patchAnagrafica.php"

export const GET_CODE = "/planner/createCode.php"

export const PUT_DEPOSIT = "/planner/addDeposit.php"

export const GET_DEPOSIT = "/planner/getDeposits.php"

export const GET_SHOULD_SEND_ALERT = "/planner/AlertListino.php"

export const PUT_DISPLACEMENT = "/planner/createDisplacement.php"

export const PATCH_DISPLACEMENT = "/planner/patchDisplacement.php"

export const DELETE_DISPLACEMENT = "/planner/deleteDisplacement.php"

export const DELETE_SECTOR = "/planner/deleteSector.php"

export const GET_DIMENSIONS = "/planner/getDimensions.php"

export const PUT_DEFAULT_ELEMENTS = "putDefaultElements.php"

export const GET_DEFAULT_ELEMENTS = "getDefaultElements.php"

export const GET_ACTIVITY_DATES = "getActivityDates.php"

export const ASSIGN_CUSTOMER_TO_BOOKING = "associateCustomer.php"

export const DELETE_BOOKING_ASSOCIATION = "deleteAssociationCustomer.php"

export const CREATE_UMBRELLA_BACKEND = "createUmbrellaFromBackend.php"

export const DELETE_UMBRELLA_BACKEND = "deleteUmbrellaFromBackend.php"

export const PATCH_UMBRELLA_BACKEND = "/planner/patchUmbrellaFromBackend.php"

export const PATCH_BOOKING_BACKEND = "patchBookingFromBackend.php"

export const DELETE_BOOKING = "deleteBooking.php"

export const GET_CUSTOMERS_TO_ASSOCIATE = "getCustomersToAssociate.php"

export const PUT_NEW_SEASON = "putNewSeason.php"

export const GET_PLACE_PLAN = "getPlacePlan.php"

export const IS_USER_OWNING_MODULE = "isUserOwningModule.php"

export const LOAD_FIELDS = "loadFields.php"

export const CALCULATE_AVAILABILITY = "calculateAvailability.php"

export const PATCH_FIELD_TIMESLOT = "patchFieldTimeslot.php"

export const DELETE_TIMESLOT = "deleteTimeslot.php"

export const ADD_TIMESLOT_FUNCTION = "addTimeslotFunction.php"

export const ADD_TIMESLOT_SERVICE = "addTimeslotService.php"

export const CHANGE_VISIBILITY_FIELD = "changeVisibility.php"

export const CHANGE_VISIBILITY_SERVICE = "changeVisibilityService.php"

export const RETRIEVE_SPORTS = "retrieveSports.php"

export const RETRIEVE_CATEGORIES = "retrieveCategories.php"

export const RETRIEVE_SPORT_IMAGES = "retrieveSportImages.php"

export const UPDATE_FIELD = "updateFieldNew2.php"

export const UPDATE_SERVICE = "updateService.php"

export const PUT_FIELD = "putFieldNew2.php"

export const PATCH_BOOKING_MASTER_FIELD = "patchBookingMasterField.php"

export const DELETE_FIELD = "deleteField.php"

export const PATCH_BOOKING_NEW = "patchBookingNew.php"

export const PATCH_IMAGE_ANAGRAFICA = "patchImageAnagrafica.php"

export const LOAD_SERVICES = "loadServices.php"

export const PUT_SERVICE = "putService.php"

export const GET_SERVICE_CATEGORIES = "getServiceCategories.php"

export const GET_SERVICE_ALLERGENS = "getAllergens.php"

export const DELETE_SERVICE = "deleteService.php"

export const DELETE_TIMESLOT_SERVICE = "deleteTimeslotService.php"

export const LOAD_PRODUCTS = "loadProducts.php"

export const DELETE_PRODUCT = "deleteProduct.php"

export const GET_INGREDIENTS = "getIngredients.php"

export const GET_VARIANTS = "getVariants.php"

export const GET_UM = "getUm.php"

export const GET_VARIANTS_WITH_PRICE = "getVariantsWithPrice.php"

export const CREATE_VARIANT_WITH_PRICE = "createVariantWithPrice.php"

export const PUT_INGREDIENT = "putIngredient.php"

export const PUT_VARIANT = "putVariant.php"

export const GET_EXTRAS = "getExtras.php"

export const PUT_EXTRA = "putExtra.php"

export const PUT_OR_PATCH_PRODUCT = "putOrPatchProduct.php"

export const PUT_PHOTO_TO_PRODUCT = "putPhotoToProduct.php"

export const LOAD_SUB_CATEGORIES = "loadSubcategories.php"

export const PATCH_OR_PUT_SUB_CATEGORY = "patchOrPutSubcategory.php"

export const GET_MODULES = "getModules.php"

export const GET_UMBRELLAS_FOT_GANTT = "retrieveUmbrellasForGantt.php"

export const CHANGE_PRODUCT_STATUS = "changeProductStatus.php"

export const CREATE_MULTIPLE_QR = "createMultipleQR.php"

export const DELETE_CATEGORY = "deleteCategory.php"

export const DELETE_SINGLE_TIMESLOT = "deleteSingleTimeslot.php"

export const CHANGE_MODULE_STATUS = "changeModuleStatus.php"

export const GET_PRETTY_URLS = "getPrettyUrls.php"

export const PUT_PRETTY_URL = "putPrettyUrl.php"

export const DELETE_PRETTY_URL = "deletePrettyUrl.php"

export const GET_PRINTING_STATUS = "getPrintingStatus.php"

export const PATCH_PRINTING_STATUS = "patchPrintingStatus.php"

export const PATCH_MENU = "patchMenu.php"

export const PATCH_TIMESLOT_BOOKING = "patchTimeslotBooking.php"

export const UPDATE_CATEGORY_ORDERING = "updateCategoryOrdering.php"

export const UPDATE_EXTRAS_ORDERING = "updateExtrasOrdering.php"

export const APPEND_EXTRAS = "appendExtras.php"

export const GET_FIELD_BOOKINGS_BY_DATE = "getFieldBookingsByDate.php"

export const DELETE_EXTRAS = "deleteExtras.php"

export const UPDATE_VARIANTS_ORDERING = "updateVariantsOrdering.php"

export const DELETE_PRODUCT_VARIANT = "deleteProductVariant.php"

export const APPEND_PRODUCT_VARIANT = "appendProductVariant.php"

export const GET_FIELD_PAYMENT_METHODS = "getFieldPaymentMethods.php"

export const PATCH_FIELD_PAYMENTS = "patchFieldPayments.php"

export const DELETE_EXTRA_BOOKING = "deleteExtraBooking.php"

export const GET_ACCOUNT_EMAIL = "getAccountEmail.php"

export const PATCH_ACCOUNT = "patchAccount.php"

export const PATCH_SERVICE_PAYMENTS = "patchServicePayment.php"

export const GET_SERVICE_PAYMENT_METHODS = "getServicePaymentMethods.php"

export const PATCH_MENU_VISIBILITY = "patchMenuVisibility.php"

export const RESET_CREDENTIALS = "resetBackCredentials.php"

export const CHECK_BACK_CREDENTIALS = "checkBackCredentials.php"

export const PATCH_CREDENTIALS = "patchCredentials.php"

export const PATCH_POPUP = "patchPopup.php"

export const GET_POPUP_INFOS = "getPopupInfos.php"

export const RETRIEVE_EVENTS = "getEvents.php"

export const GET_ACCEPTED_PAYMENTS = "getAcceptedPayments.php"
